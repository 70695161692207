import React, { useState, useEffect, useRef } from 'react';
import { 
  Send, 
  PlusCircle, 
  Search, 
  Edit, 
  Trash2, 
  User, 
  CheckSquare, 
  FileText, 
  Lightbulb, 
  Image, 
  Mic, 
  Paperclip,
  MessageSquare,
  X,
  ChevronLeft,
  MoreVertical
} from 'lucide-react';

const App = () => {
  const [activeChat, setActiveChat] = useState(null);
  const [chats, setChats] = useState([
    { id: 1, name: 'John Doe', icon: <User />, type: 'transaction', items: [] },
    { id: 2, name: 'Todo List', icon: <CheckSquare />, type: 'todo', items: [] },
    { id: 3, name: 'Daily Review', icon: <FileText />, type: 'review', items: [] },
    { id: 4, name: 'Great Ideas', icon: <Lightbulb />, type: 'idea', items: [] },
  ]);
  const [isEditing, setIsEditing] = useState(false);
  const [editName, setEditName] = useState('');
  const messageEndRef = useRef(null);
  const [inputText, setInputText] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredChats, setFilteredChats] = useState(chats);
  const [selectedImage, setSelectedImage] = useState(null);
  const [transactionTitle, setTransactionTitle] = useState('');
  const [transactionPurposes, setTransactionPurposes] = useState([{ purpose: '', amount: '' }]);
  const [isRecording, setIsRecording] = useState(false);
  const [audioURL, setAudioURL] = useState(null);
  const [showNewChatModal, setShowNewChatModal] = useState(false);
  const [newChatName, setNewChatName] = useState('');
  const [newChatType, setNewChatType] = useState('general');
  const [showSidebar, setShowSidebar] = useState(true);
const [isChatVisible, setIsChatVisible] = useState(false);


  let mediaRecorder = null;
  const chunks = [];

  useEffect(() => {
    scrollToBottom();
  }, [activeChat?.items]);

  useEffect(() => {
    const results = chats.filter(chat =>
      chat.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredChats(results);
  }, [searchTerm, chats]);

  const scrollToBottom = () => {
    messageEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleSendMessage = () => {
    if (!activeChat || (!inputText.trim() && !selectedImage && !audioURL && activeChat.type !== 'transaction')) return;

    let newItem;
    if (selectedImage) {
      newItem = {
        type: 'image',
        content: selectedImage,
        date: new Date(),
        sender: 'user'
      };
      setSelectedImage(null);
    } else if (audioURL) {
      newItem = {
        type: 'audio',
        content: audioURL,
        date: new Date(),
        sender: 'user'
      };
      setAudioURL(null);
    } else if (activeChat.type === 'transaction') {
      if (!transactionTitle || !transactionPurposes.some(p => p.purpose && p.amount)) return;
      newItem = {
        type: 'transaction',
        title: transactionTitle,
        purposes: transactionPurposes.filter(p => p.purpose && p.amount),
        date: new Date(),
        sender: 'user'
      };
      setTransactionTitle('');
      setTransactionPurposes([{ purpose: '', amount: '' }]);
    } else {
      newItem = {
        type: activeChat.type,
        text: inputText,
        date: new Date(),
        sender: 'user'
      };
    }

    updateChat(newItem);
    setInputText('');
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const updateChat = (newItem) => {
    const updatedChats = chats.map(chat => 
      chat.id === activeChat.id 
        ? { ...chat, items: [...chat.items, newItem] }
        : chat
    );
    setChats(updatedChats);
    setActiveChat(prevChat => ({
      ...prevChat,
      items: [...prevChat.items, newItem]
    }));
  };

  const addNewChat = () => {
    if (!newChatName.trim()) return;
    const newChat = {
      id: Date.now(),
      name: newChatName,
      icon: getIconForType(newChatType),
      type: newChatType,
      items: []
    };
    setChats(prevChats => [...prevChats, newChat]);
    setActiveChat(newChat);
    setShowNewChatModal(false);
    setNewChatName('');
    setNewChatType('general');
  };

  const getIconForType = (type) => {
    switch(type) {
      case 'transaction': return <User />;
      case 'todo': return <CheckSquare />;
      case 'review': return <FileText />;
      case 'idea': return <Lightbulb />;
      default: return <MessageSquare />;
    }
  };

  const deleteChat = (chatId) => {
    setChats(prevChats => prevChats.filter(chat => chat.id !== chatId));
    if (activeChat && activeChat.id === chatId) {
      setActiveChat(null);
    }
  };

  const startEditing = () => {
    setIsEditing(true);
    setEditName(activeChat.name);
  };

  const saveEdit = () => {
    if (editName.trim()) {
      setChats(prevChats => prevChats.map(chat => 
        chat.id === activeChat.id ? { ...chat, name: editName } : chat
      ));
      setActiveChat(prevChat => ({ ...prevChat, name: editName }));
    }
    setIsEditing(false);
  };

  const formatDate = (date) => {
    const now = new Date();
    const diffMs = now - date;
    const diffMins = Math.round(diffMs / 60000);
    const diffHours = Math.round(diffMs / 3600000);
    const diffDays = Math.round(diffMs / 86400000);

    if (diffMins < 1) return 'Just now';
    if (diffMins < 60) return `${diffMins}m ago`;
    if (diffHours < 24) return `${diffHours}h ago`;
    if (diffDays === 1) return 'Yesterday';
    if (diffDays < 7) return date.toLocaleDateString('en-US', { weekday: 'long' });
    return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

const handleChatClick = (chat) => {
  setActiveChat(chat);
  setIsChatVisible(true);
  setShowSidebar(false);
};

const handleBackClick = () => {
  setIsChatVisible(false);
  setShowSidebar(true);
};
  const startRecording = () => {
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(stream => {
        mediaRecorder = new MediaRecorder(stream);
        mediaRecorder.start();
        setIsRecording(true);

        mediaRecorder.addEventListener("dataavailable", event => {
          chunks.push(event.data);
        });

        mediaRecorder.addEventListener("stop", () => {
          const blob = new Blob(chunks, { type: 'audio/ogg; codecs=opus' });
          const audioURL = URL.createObjectURL(blob);
          setAudioURL(audioURL);
          setIsRecording(false);
        });
      });
  };

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
    }
  };

  const renderChatItem = (item, index) => {
    const bubbleClass = `chat-bubble ${item.sender === 'user' ? 'sent' : 'received'}`;
    const timeClass = `chat-time ${item.sender === 'user' ? 'sent' : 'received'}`;

    switch (item.type) {
      case 'transaction':
        return (
          <div key={index} className={bubbleClass}>
            <div className="transaction-content">
              <h4>{item.title}</h4>
              {item.purposes.map((purpose, i) => (
                <div key={i} className="transaction-purpose">
                  <span>{purpose.purpose}</span>
                  <span className="transaction-amount">${parseFloat(purpose.amount).toFixed(2)}</span>
                </div>
              ))}
            </div>
            <span className={timeClass}>{formatDate(item.date)}</span>
          </div>
        );
      case 'image':
        return (
          <div key={index} className={bubbleClass}>
            <img src={item.content} alt="User uploaded" className="chat-image" />
            <span className={timeClass}>{formatDate(item.date)}</span>
          </div>
        );
      case 'audio':
        return (
          <div key={index} className={bubbleClass}>
            <audio controls src={item.content} className="chat-audio" />
            <span className={timeClass}>{formatDate(item.date)}</span>
          </div>
        );
      default:
        return (
          <div key={index} className={bubbleClass}>
            <p>{item.text}</p>
            <span className={timeClass}>{formatDate(item.date)}</span>
          </div>
        );
    }
  };

  return (
    <div className="app-container">
      <div className={`sidebar ${showSidebar ? 'show' : ''}`}>
        <div className="sidebar-header">
          <h1>WhatsApp</h1>
          <div className="sidebar-actions">
            <button className="action-button">
              <Search size={20} />
            </button>
            <button className="action-button" onClick={() => setShowNewChatModal(true)}>
              <MessageSquare size={20} />
            </button>
            <button className="action-button">
              <MoreVertical size={20} />
            </button>
          </div>
        </div>
        <div className="search-container">
          <Search className="search-icon" size={20} />
          <input 
            type="text" 
            placeholder="Search or start new chat" 
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="chat-list">
          {filteredChats.map(chat => (
            <div
  key={chat.id}
  onClick={() => handleChatClick(chat)}
  className={`chat-item ${activeChat?.id === chat.id ? 'active' : ''}`}
>
              <div className="chat-icon">{chat.icon}</div>
              <div className="chat-info">
                <h3>{chat.name}</h3>
                <p>{chat.items.length > 0 ? (chat.items[chat.items.length - 1].text || 'Image') : 'No messages yet'}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className={`main-chat-area ${isChatVisible ? 'show' : ''}`}>
  {activeChat ? (
    <>
      <div className="chat-header">
        <button className="back-button" onClick={handleBackClick}>
          <ChevronLeft size={24} />
        </button>
              <div className="chat-title">
                <div className="chat-icon">{activeChat.icon}</div>
                {isEditing ? (
                  <input
                    type="text"
                    value={editName}
                    onChange={(e) => setEditName(e.target.value)}
                    onBlur={saveEdit}
                    className="edit-chat-name"
                  />
                ) : (
                  <h2>{activeChat.name}</h2>
                )}
              </div>
              <button onClick={startEditing} className="edit-button">
                <Edit size={18} />
              </button>
            </div>
            <div className="chat-messages">
              {activeChat.items.map(renderChatItem)}
              <div ref={messageEndRef} />
            </div>
            <div className="chat-input">
              {selectedImage && (
                <div className="selected-image-preview">
                  <img src={selectedImage} alt="Selected" />
                  <button onClick={() => setSelectedImage(null)}></button>
                </div>
              )}
              {audioURL && (
                <div className="selected-audio-preview">
                  <audio controls src={audioURL} />
                  <button onClick={() => setAudioURL(null)}></button>
                </div>
              )}
              {activeChat.type === 'transaction' ? (
                <div className="transaction-input">
                  <input
                    type="text"
                    value={transactionTitle}
                    onChange={(e) => setTransactionTitle(e.target.value)}
                    placeholder="Transaction Title"
                  />
                  {transactionPurposes.map((purpose, index) => (
                    <div key={index} className="purpose-input">
                      <input
                        type="text"
                        value={purpose.purpose}
                        onChange={(e) => {
                          const newPurposes = [...transactionPurposes];
                          newPurposes[index].purpose = e.target.value;
                          setTransactionPurposes(newPurposes);
                        }}
                        placeholder="Purpose"
                      />
                      <input
                        type="number"
                        value={purpose.amount}
                        onChange={(e) => {
                          const newPurposes = [...transactionPurposes];
                          newPurposes[index].amount = e.target.value;
                          setTransactionPurposes(newPurposes);
                        }}
                        placeholder="Amount"
                      />
                    </div>
                  ))}
                  <button onClick={() => setTransactionPurposes([...transactionPurposes, { purpose: '', amount: '' }])}>
                    Add Purpose
                  </button>
                </div>
              ) : (
                <textarea
                  value={inputText}
                  onChange={(e) => setInputText(e.target.value)}
                  onKeyPress={handleKeyPress}
                  placeholder="Type a message"
                  rows="1"
                />
              )}
              <div className="input-actions">
                <button className="attach-button">
                  <label htmlFor="image-upload">
                    <Paperclip size={24} />
                  </label>
                  <input 
                    id="image-upload" 
                    type="file" 
                    accept="image/*" 
                    onChange={handleImageUpload} 
                    style={{ display: 'none' }}
                  />
                </button>
                {inputText.trim() || selectedImage || audioURL || (activeChat.type === 'transaction' && transactionTitle) ? (
                  <button onClick={handleSendMessage} className="send-button">
                    <Send size={24} />
                  </button>
                ) : (
                  <button 
                    onMouseDown={startRecording}
                    onMouseUp={stopRecording}
                    onMouseLeave={stopRecording}
                    className={`record-button ${isRecording ? 'recording' : ''}`}
                  >
                    <Mic size={24} />
                  </button>
                )}
              </div>
            </div>
          </>
        ) : (
             <div className="no-chat-selected">
      <p>Select a chat to start messaging</p>
    </div>
  )}
</div>

      {showNewChatModal && (
        <div className="modal">
          <div className="modal-content">
            <h2>Create New Chat</h2>
            <input
              type="text"
              value={newChatName}
              onChange={(e) => setNewChatName(e.target.value)}
              placeholder="Chat Name"
            />
            <select value={newChatType} onChange={(e) => setNewChatType(e.target.value)}>
              <option value="general">General</option>
              <option value="transaction">Transaction</option>
              <option value="todo">Todo</option>
              <option value="review">Review</option>
              <option value="idea">Idea</option>
            </select>
            <div className="modal-buttons">
              <button onClick={() => setShowNewChatModal(false)}>Cancel</button>
              <button onClick={addNewChat}>Create</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default App;

