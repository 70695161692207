import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import {
  Send,
  Search,
  Edit,
  Trash2,
  CheckSquare,
  FileText,
  Lightbulb,
  Mic,
  Paperclip,
  MessageSquare,
  ChevronLeft,
  MoreVertical,
  DollarSign,
  Menu
} from 'lucide-react';

const API_BASE_URL = 'http://213.210.37.135:6565/api';

const App = () => {
  const [activeChat, setActiveChat] = useState(null);
  const [chats, setChats] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editName, setEditName] = useState('');
  const messageEndRef = useRef(null);
  const [inputText, setInputText] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredChats, setFilteredChats] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [transactionTitle, setTransactionTitle] = useState('');
  const [transactionPurposes, setTransactionPurposes] = useState([{ purpose: '', amount: '' }]);
  const [isRecording, setIsRecording] = useState(false);
  const [audioURL, setAudioURL] = useState(null);
  const [showNewChatModal, setShowNewChatModal] = useState(false);
  const [newChatName, setNewChatName] = useState('');
  const [newChatType, setNewChatType] = useState('general');
  const [sidebarVisible, setSidebarVisible] = useState(true);
  const [isChatVisible, setIsChatVisible] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [chatToDelete, setChatToDelete] = useState(null);

  useEffect(() => {
    fetchChats();
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setSidebarVisible(true);
        setIsChatVisible(!!activeChat);
      } else {
        setSidebarVisible(!isChatVisible);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call it initially
    return () => window.removeEventListener('resize', handleResize);
  }, [activeChat, isChatVisible]);

  useEffect(() => {
    scrollToBottom();
  }, [activeChat?.items]);

  useEffect(() => {
    const results = chats.filter(chat =>
      chat.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredChats(results);
  }, [searchTerm, chats]);

  const fetchChats = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/chats`);
      const fetchedChats = response.data.map(chat => ({
        ...chat,
        icon: getIconForType(chat.type),
        items: [], // We'll fetch messages when a chat is clicked
      }));
      setChats(fetchedChats);
      setFilteredChats(fetchedChats);
    } catch (error) {
      console.error('Error fetching chats:', error);
    }
  };

  const fetchMessages = async (chatId) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/chats/${chatId}/messages`);
      const messages = response.data.map(msg => ({
        ...msg,
        date: new Date(msg.created_at + 'Z'), // Treat as UTC
        sender: 'user', // Adjust this based on your message schema
      }));
      return messages;
    } catch (error) {
      console.error('Error fetching messages:', error);
      return [];
    }
  };

  const scrollToBottom = () => {
    messageEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleSendMessage = async () => {
    if (!activeChat || (!inputText.trim() && !selectedImage && !audioURL && activeChat.type !== 'transaction')) return;

    let newItem;

    if (selectedImage) {
      // Handle image upload (not implemented)
      alert('Image upload is not implemented in this example.');
    } else if (audioURL) {
      // Handle audio message (not implemented)
      alert('Audio messages are not implemented in this example.');
    } else if (activeChat.type === 'transaction') {
      if (!transactionTitle || !transactionPurposes.some(p => p.purpose && p.amount)) return;
      const transactionData = {
        title: transactionTitle,
        purposes: transactionPurposes.filter(p => p.purpose && p.amount),
      };
      newItem = {
        content: JSON.stringify(transactionData),
        type: 'transaction',
      };

      try {
        const response = await axios.post(
          `${API_BASE_URL}/chats/${activeChat.id}/messages`,
          newItem
        );

        const savedMessage = {
          ...response.data,
          date: new Date(response.data.created_at + 'Z'), // Treat as UTC
          sender: 'user',
        };

        updateChat(savedMessage);
      } catch (error) {
        console.error('Error sending transaction message:', error);
      }

      setTransactionTitle('');
      setTransactionPurposes([{ purpose: '', amount: '' }]);
    } else {
      // Text message
      newItem = {
        content: inputText,
        type: 'text',
      };

      try {
        const response = await axios.post(
          `${API_BASE_URL}/chats/${activeChat.id}/messages`,
          newItem
        );

        const savedMessage = {
          ...response.data,
          date: new Date(response.data.created_at + 'Z'), // Treat as UTC
          sender: 'user',
        };

        updateChat(savedMessage);
      } catch (error) {
        console.error('Error sending message:', error);
      }
    }

    setInputText('');
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const updateChat = (newItem) => {
    setActiveChat(prevChat => ({
      ...prevChat,
      items: [...prevChat.items, newItem],
    }));
  };

  const addNewChat = async () => {
    const newChatData = {
      name: newChatName,
      type: newChatType,
    };

    try {
      const response = await axios.post(`${API_BASE_URL}/chats`, newChatData);
      const newChat = {
        ...response.data,
        icon: getIconForType(response.data.type),
        items: [],
      };

      setChats(prevChats => [...prevChats, newChat]);
      setActiveChat(newChat);
      setShowNewChatModal(false);
      setNewChatName('');
      setNewChatType('general');
      setIsChatVisible(true);
      if (window.innerWidth <= 768) {
        setSidebarVisible(false);
      }
    } catch (error) {
      console.error('Error creating new chat:', error);
    }
  };

  const getIconForType = (type) => {
    switch (type) {
      case 'transaction': return <DollarSign />;
      case 'todo': return <CheckSquare />;
      case 'review': return <FileText />;
      case 'idea': return <Lightbulb />;
      default: return <MessageSquare />;
    }
  };

  const handleDeleteClick = (e, chatId) => {
    e.stopPropagation();
    setChatToDelete(chatId);
    setShowDeleteConfirmation(true);
  };

  const confirmDelete = async () => {
    if (chatToDelete) {
      try {
        await axios.delete(`${API_BASE_URL}/chats/${chatToDelete}`);
        setChats(prevChats => prevChats.filter(chat => chat.id !== chatToDelete));
        if (activeChat && activeChat.id === chatToDelete) {
          setActiveChat(null);
          setIsChatVisible(false);
          if (window.innerWidth <= 768) {
            setSidebarVisible(true);
          }
        }
      } catch (error) {
        console.error('Error deleting chat:', error);
      }
    }
    setShowDeleteConfirmation(false);
    setChatToDelete(null);
  };

  const cancelDelete = () => {
    setShowDeleteConfirmation(false);
    setChatToDelete(null);
  };

  const handleChatClick = async (chat) => {
    try {
      const messages = await fetchMessages(chat.id);
      setActiveChat({
        ...chat,
        items: messages,
      });
      setIsChatVisible(true);
      if (window.innerWidth <= 768) {
        setSidebarVisible(false);
      }
    } catch (error) {
      console.error('Error selecting chat:', error);
    }
  };

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
    if (window.innerWidth <= 768) {
      setIsChatVisible(!sidebarVisible);
    }
  };

  const startEditing = () => {
    setIsEditing(true);
    setEditName(activeChat.name);
  };

  const saveEdit = async () => {
    if (editName.trim()) {
      try {
        await axios.put(`${API_BASE_URL}/chats/${activeChat.id}`, {
          name: editName,
        });

        setChats(prevChats => prevChats.map(chat =>
          chat.id === activeChat.id ? { ...chat, name: editName } : chat
        ));
        setActiveChat(prevChat => ({ ...prevChat, name: editName }));
      } catch (error) {
        console.error('Error updating chat:', error);
      }
    }
    setIsEditing(false);
  };

  const formatDate = (date) => {
    const now = new Date();
    const dateWithoutTime = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    const nowWithoutTime = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const diffTime = nowWithoutTime - dateWithoutTime;
    const diffDays = diffTime / (1000 * 3600 * 24);

    if (diffDays === 0) return 'Today';
    if (diffDays === 1) return 'Yesterday';
    return date.toLocaleDateString('en-IN', { day: 'numeric', month: 'long', year: 'numeric' });
  };

  const formatTime = (date) => {
    return date.toLocaleTimeString('en-IN', { hour: '2-digit', minute: '2-digit' });
  };

  const handleImageUpload = (e) => {
    alert('Image upload is not implemented in this example.');
  };

  const startRecording = () => {
    alert('Audio recording is not implemented in this example.');
  };

  const stopRecording = () => { };

  const handleTagTransaction = (message, tag) => {
    alert(`Tagging transaction as ${tag} is not implemented in this example.`);
  };

  const renderChatMessages = () => {
    if (!activeChat || !activeChat.items) return null;

    let lastMessageDate = null;
    const messagesWithDates = [];

    activeChat.items.forEach((item, index) => {
      const messageDate = new Date(item.date);
      const messageDateString = formatDate(messageDate);

      if (lastMessageDate !== messageDateString) {
        messagesWithDates.push(
          <div key={`date-${index}`} className="date-separator">
            <span>{messageDateString}</span>
          </div>
        );
        lastMessageDate = messageDateString;
      }

      messagesWithDates.push(renderChatItem(item, index));
    });

    return messagesWithDates;
  };

  const renderChatItem = (item, index) => {
    const bubbleClass = `chat-bubble ${item.sender === 'user' ? 'sent' : 'received'}`;
    const timeClass = `chat-time ${item.sender === 'user' ? 'sent' : 'received'}`;

    switch (item.type) {
      case 'transaction':
        const transactionData = item.content ? JSON.parse(item.content) : null;
        return (
          <div key={index} className={`${bubbleClass} transaction-bubble`}>
            {transactionData && (
              <div className="transaction-content">
                <h4>{transactionData.title}</h4>
                <div className="transaction-table-container">
                  <table className="transaction-table">
                    <thead>
                      <tr>
                        <th>Purpose</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {transactionData.purposes.map((purpose, i) => (
                        <tr key={i}>
                          <td>{purpose.purpose}</td>
                          <td className="transaction-amount">₹{parseFloat(purpose.amount).toFixed(2)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {item.tag && <div className={`transaction-tag ${item.tag}`}>{item.tag}</div>}
              </div>
            )}
            <span className={timeClass}>{formatTime(new Date(item.date))}</span>
          </div>
        );
      case 'image':
        // Handle image messages if implemented
        break;
      case 'audio':
        // Handle audio messages if implemented
        break;
      default:
        return (
          <div key={index} className={bubbleClass}>
            <p>{item.content}</p>
            <span className={timeClass}>{formatTime(new Date(item.date))}</span>
          </div>
        );
    }
  };

  return (
    <div className="app-container">
      {/* Sidebar */}
      <div className={`sidebar ${sidebarVisible ? 'visible' : 'hidden'}`}>
        <div className="sidebar-header">
          <h1>WhatsApp Planner</h1>
          <div className="sidebar-actions">
            <button className="action-button">
              <Search size={20} />
            </button>
            <button className="action-button" onClick={() => setShowNewChatModal(true)}>
              <MessageSquare size={20} />
            </button>
            <button className="action-button">
              <MoreVertical size={20} />
            </button>
          </div>
        </div>
        <div className="search-container">
          <Search className="search-icon" size={20} />
          <input
            type="text"
            placeholder="Search or start new chat"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="chat-list">
          {filteredChats.map(chat => (
            <div
              key={chat.id}
              onClick={() => handleChatClick(chat)}
              className={`chat-item ${activeChat?.id === chat.id ? 'active' : ''}`}
            >
              <div className="chat-icon">{chat.icon}</div>
              <div className="chat-info">
                <h3>{chat.name}</h3>
                <p>{chat.items.length > 0 ? (chat.items[chat.items.length - 1].content || 'Image') : 'No messages yet'}</p>
              </div>
              <button
                className="delete-chat-button"
                onClick={(e) => handleDeleteClick(e, chat.id)}
              >
                <Trash2 size={16} />
              </button>
            </div>
          ))}
        </div>
      </div>

      {/* Main Chat Area */}
      <div className={`main-chat-area ${isChatVisible ? 'visible' : 'hidden'}`}>
        {activeChat ? (
          <>
            {/* Chat Header */}
            <div className="chat-header">
              <button className="menu-button" onClick={toggleSidebar}>
                {window.innerWidth <= 768 ? <ChevronLeft size={24} /> : <Menu size={24} />}
              </button>
              <div className="chat-title">
                <div className="chat-icon">{activeChat.icon}</div>
                {isEditing ? (
                  <input
                    type="text"
                    value={editName}
                    onChange={(e) => setEditName(e.target.value)}
                    onBlur={saveEdit}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        saveEdit();
                      }
                    }}
                    className="edit-chat-name"
                    autoFocus
                  />
                ) : (
                  <h2>{activeChat.name}</h2>
                )}
              </div>
              <div className="chat-header-actions">
                <button onClick={startEditing} className="edit-button">
                  <Edit size={18} />
                </button>
              </div>
            </div>

            {/* Chat Messages */}
            <div className="chat-messages">
              {renderChatMessages()}
              <div ref={messageEndRef} />
            </div>

            {/* Chat Input */}
            <div className="chat-input">
              {selectedImage && (
                <div className="selected-image-preview">
                  <img src={selectedImage} alt="Selected" />
                  <button onClick={() => setSelectedImage(null)}>×</button>
                </div>
              )}
              {audioURL && (
                <div className="selected-audio-preview">
                  <audio controls src={audioURL} />
                  <button onClick={() => setAudioURL(null)}>×</button>
                </div>
              )}
              {activeChat.type === 'transaction' ? (
                <div className="transaction-input">
                  <input
                    type="text"
                    value={transactionTitle}
                    onChange={(e) => setTransactionTitle(e.target.value)}
                    placeholder="Transaction Title"
                  />
                  {transactionPurposes.map((purpose, index) => (
                    <div key={index} className="purpose-input">
                      <input
                        type="text"
                        value={purpose.purpose}
                        onChange={(e) => {
                          const newPurposes = [...transactionPurposes];
                          newPurposes[index].purpose = e.target.value;
                          setTransactionPurposes(newPurposes);
                        }}
                        placeholder="Purpose"
                      />
                      <input
                        type="number"
                        value={purpose.amount}
                        onChange={(e) => {
                          const newPurposes = [...transactionPurposes];
                          newPurposes[index].amount = e.target.value;
                          setTransactionPurposes(newPurposes);
                        }}
                        placeholder="Amount"
                      />
                    </div>
                  ))}
                  <div className="transaction-actions">
                    <button onClick={() => setTransactionPurposes([...transactionPurposes, { purpose: '', amount: '' }])}>
                      Add Purpose
                    </button>
                    <button onClick={handleSendMessage} className="send-button transaction-send-button">
                      <Send size={24} />
                    </button>
                  </div>
                </div>
              ) : (
                <div className="text-input-container">
                  <textarea
                    value={inputText}
                    onChange={(e) => setInputText(e.target.value)}
                    onKeyPress={handleKeyPress}
                    placeholder="Type a message"
                    rows="1"
                    className="message-input"
                  />
                  <div className="input-actions">
                    <button className="attach-button">
                      <label htmlFor="image-upload">
                        <Paperclip size={24} />
                      </label>
                      <input
                        id="image-upload"
                        type="file"
                        accept="image/*"
                        onChange={handleImageUpload}
                        style={{ display: 'none' }}
                      />
                    </button>
                    {inputText.trim() || selectedImage || audioURL ? (
                      <button onClick={handleSendMessage} className="send-button">
                        <Send size={24} />
                      </button>
                    ) : (
                      <button
                        onMouseDown={startRecording}
                        onMouseUp={stopRecording}
                        onMouseLeave={stopRecording}
                        className={`record-button ${isRecording ? 'recording' : ''}`}
                      >
                        <Mic size={24} />
                      </button>
                    )}
                  </div>
                </div>
              )}
            </div>
          </>
        ) : (
          <div className="no-chat-selected">
            <p>Select a chat to start messaging</p>
          </div>
        )}
      </div>

      {/* New Chat Modal */}
      {showNewChatModal && (
        <div className="modal">
          <div className="modal-content">
            <h2>Create New Chat</h2>
            <input
              type="text"
              value={newChatName}
              onChange={(e) => setNewChatName(e.target.value)}
              placeholder="Chat Name"
            />
            <select value={newChatType} onChange={(e) => setNewChatType(e.target.value)}>
              <option value="general">General</option>
              <option value="transaction">Transaction</option>
              <option value="todo">Todo</option>
              <option value="review">Review</option>
              <option value="idea">Idea</option>
            </select>
            <div className="modal-buttons">
              <button onClick={() => setShowNewChatModal(false)} className="cancel">Cancel</button>
              <button onClick={addNewChat}>Create</button>
            </div>
          </div>
        </div>
      )}

      {/* Delete Confirmation Modal */}
      {showDeleteConfirmation && (
        <div className="modal delete-popup">
          <div className="modal-content">
            <h2>Confirm Delete</h2>
            <p>Are you sure you want to delete this chat?</p>
            <div className="modal-buttons">
              <button onClick={cancelDelete} className="cancel">Cancel</button>
              <button onClick={confirmDelete}>Delete</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default App;